import { createStore } from 'vuex';
export default createStore({
    state: {
        el_menubBackGroundColor: "#cccccc05",
        el_menuTextColor: "#fff",
        el_menuActiveTextColor: "#ffd04b",
        scrollTop: 0,
    },
    getters: {},
    mutations: {
        newColor(state) {
            state.el_menubBackGroundColor = "#f9f9f9";
            state.el_menuTextColor = "#808080";
        },
        oldColor(state) {
            state.el_menubBackGroundColor = "#cccccc05";
            state.el_menuTextColor = "#fff";
        },
    },
    actions: {
        newColor_Context(context) {
            context.commit("newColor");
        },
        oldColor_Context(context) {
            context.commit("oldColor");
        },
    },
    modules: {}
});
