import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import 'element-plus/dist/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import VueAnimateOnScroll from 'vue3-animate-onscroll';
const app = createApp(App);
// app.use(ElementPlus)//只需要注册一次，否则会被覆盖
app.use(ElementPlus, { locale: zhCn });
app.use(router);
app.use(store);
app.use(VueAnimateOnScroll);
//全局注册图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.mount('#app');
