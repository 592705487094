import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/Layout/index.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('../views/Content/Home.vue'),
            },
            {
                path: '/trainroom',
                name: 'TrainRoom',
                component: () => import('../views/Content/TrainRoom.vue')
            },
            {
                path: '/pack',
                name: 'Pack',
                component: () => import('../views/Content/Pack.vue')
            },
            {
                path: '/lithiumlattery',
                name: 'LithiumBattery',
                component: () => import('../views/Content/LithiumBattery.vue')
            },
            {
                path: '/solution',
                name: 'Solution',
                component: () => import('../views/Content/RobotUse/Solution.vue')
            },
            {
                path: '/AboutUs',
                name: 'AboutUs',
                component: () => import('../views/Content/EnterFosse/AboutUs.vue')
            },
            {
                path: '/EnterpriseDynamics',
                name: 'EnterpriseDynamics',
                component: () => import('../views/Content/EnterFosse/EnterpriseDynamics.vue'),
            },
            {
                path: '/JoinUs',
                name: 'JoinUs',
                component: () => import('../views/Content/EnterFosse/JoinUs.vue')
            },
            {
                path: '/ContactUs',
                name: 'ContactUs',
                component: () => import('../views/Content/EnterFosse/ContactUs.vue')
            },
            {
                path: '/DynamicsDetail',
                name: 'DynamicsDetail',
                component: () => import('../views/Content/EnterFosse/DynamicsDetail.vue')
            },
        ]
    },
    {
        path: '/supplier',
        name: 'supplier',
        component: () => import('../views/supplier.vue')
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return {
        //   el:"#TrainRoom3",
        // }
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return {
                top: 0,
                behavior: 'smooth'
            };
        }
        // if (to.hash) {
        //   console.log("to.hash",to.hash)
        //   return {
        //     el:to.hash
        //   }
        // }
    }
});
export default router;
