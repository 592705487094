<template>
  <router-view/>
</template>

<style>
html,body{
  margin: 0;
  padding: 0;
  width: 100%;
  /* height: 100%; */
  background-color: #FFFFFF;
  list-style:none;
  text-decoration:none;
  font-family: '微软雅黑', "Source Han Sans CN", "PingFang SC", 'STHeiti', "Microsoft Yahei", "Heiti SC";
  scroll-behavior:smooth;/* 全局丝滑滚动回顶部 */
}
 ul li{
  list-style: none;
 }

 a{
  text-decoration: none;
 }
 /* <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
  复制cdn地址，对源码直接复制，保存到记事本，并更改文件名称为animate.css */
/* @import "./assets/css/animate.css" */

 /* @media (min-width: 1024px){
    body{font-size: 18px}
    } 
 
  @media (min-width: 1100px) {
    body{font-size: 20px}
  } 

  @media (min-width: 1280px) {
    body{font-size: 22px;}
  }

  @media (min-width: 1366px) {

    body{font-size: 24px;}
  }  

  @media (min-width: 1440px) {
    body{font-size: 25px !important;}
  } 

  @media (min-width: 1680px) {
    body{font-size: 28px;}
  } 
  @media (min-width: 1920px) {
    body{font-size: 33px;}
  }  */
   
</style>
